.timeline {
  margin: 50px 60px;
  height: 4px;
  background-color: #ccc;
}

.timeline-progress {
	width: 33%;
	height: 100%;
	background-color: orange;
}

.timeline-items {
	margin-left: -10px;
	margin-right: -10px;
	margin-top: -12px;
	display: flex;
	justify-content: space-between;
}

.timeline-item {
	position: relative;
}

.timeline-item::before {
	content: '';
	width: 20px;
	height: 20px;
	background-color: #ccc;
	display: block;
	border-radius: 100%;
}
.timeline-content {
	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #ddd;
	width: 120px;
	padding: 5px 10px;
	border-radius: 5px;
	text-align: center;
}
.timeline-item.active::before {
	background-color: orange;
}

.timeline-item.active .timeline-content {
	color: #fff;
	background-color: orange;
}

.timeline-item.active .timeline-content::before {
	background-color: orange;
}
.timeline-box {
  height: 100px
}
