#graph {
  text-align: center;
  overflow: auto;
}

.node {
  text {
    font: 12px sans-serif;
  }
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.link {
  path {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
  }
  text {
    font: 12px sans-serif;
  }
}

.highlighted-text {
  color: #76a96c;
  background: #e0f3ff;
  font-weight: bold;
}

.center-text {
  text-anchor: middle;
}