.custom-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.custom-list li {
  margin-bottom: 0.5rem;
}

.custom-info .btn {
    padding: 0;
}

.custom-form-button {
  display: grid;
  height: 100%;
}

.custom-form-button .btn {
  margin: auto;
}

.font-bold {
  font-weight: bold;
}

.custom-label-check {
  margin: 0 20px;
  padding-bottom: 20px;
}

// Search Box customization
.input-holder-first {
  border-radius: 50px 0 0 50px !important;
}
.input-holder-middle {
  border-radius: 0 !important;
}
.input-holder-last {
  border-radius: 0 50px 50px 0 !important;
}

.custom-input-row-no-label {
  top: 27px
}
.custom-button-row-no-label {
  top: 32px
}