:root {
  scroll-behavior: smooth;
}

a {
  color: var(--color-black);
  text-decoration: none;
  font-style: normal;
}

a:hover {
  color: var(--color-links-hover);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  ;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: var(--color-white);
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  -webkit-animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
  # Sections & Section Header
  --------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.section-header p {
  margin: 0 auto;
  color: var(--color-secondary-light);
}

@media (min-width: 1280px) {
  .section-header p {
    max-width: 80%;
  }
}

@media (min-width: 500px) {
  section {
    padding: 30px 0;
  }
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: rgba(var(--color-secondary-rgb), 0.05);
  min-height: 40px;
  margin-top: 76px;
}

.breadcrumbs h2 {
  font-size: 30px;
  font-weight: 300;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: var(--color-secondary-light);
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
  # Scroll top button
  --------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 995;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--color-white);
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
  color: var(--color-white);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  .banner-description,
  .banner-content {
    display: block !important;
  }

}

/*--------------------------------------------------------------
  # top bar
  --------------------------------------------------------------*/
#topbar {
  background: #f1f1f1;
  font-size: 14px;
  padding: 0;
  height: 40px;
}

#topbar a {
  margin-left: 5px;
  color: rgba(var(--color-black-rgb), 0.7);
}

#topbar a:hover {
  color: var(--color-black);
}

@media screen and (max-width: 768px) {
  #topbar {
    height: 30px;
  }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
.header {
  padding: 50px 0 15px;
  transition: all 0.5s;
  z-index: 997;
  background: var(--color-white);
}

.header.sticked {
  background: var(--color-white);
  box-shadow: 0px 2px 20px rgba(var(--color-secondary-rgb), 0.1);
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 32px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.header .logo h1 span {
  color: var(--color-primary);
  font-weight: 500;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  font-size: 16px;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 8px 23px;
  border-radius: 4px;
  transition: 0.3s;
  font-family: var(--font-secondary);
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--color-white);
  background: #4d6f5b;
}

@media (max-width: 1279px) {

  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    margin-right: 50px;
  }
}

/*--------------------------------------------------------------
  # Desktop Navigation
  --------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
    position: relative;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar>ul>li {
    white-space: nowrap;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 400;
    color: rgba(var(--color-secondary-dark-rgb), 1);
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before {
    visibility: visible;
    transform: scaleX(0.7);
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown a:hover:before,
  .navbar .dropdown:hover>a:before,
  .navbar .dropdown .active:before {
    visibility: hidden;
  }

  .navbar .dropdown a:hover,
  .navbar .dropdown .active,
  .navbar .dropdown .active:focus,
  .navbar .dropdown:hover>a {
    color: var(--color-white);
    background: var(--color-secondary);
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    padding: 0 0 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: var(--color-secondary);
    transition: 0.3s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: rgba(var(--color-white-rgb), 0.5);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-white);
    background: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    visibility: visible;
  }

  .navbar .megamenu {
    position: static;
  }

  .navbar .megamenu ul {
    right: 0;
    padding: 10px;
    display: flex;
  }

  .navbar .megamenu ul li {
    flex: 1;
  }

  .navbar .megamenu ul li a,
  .navbar .megamenu ul li:hover>a {
    color: rgba(var(--color-white-rgb), 0.5);
    background: none;
  }

  .navbar .megamenu ul li a:hover,
  .navbar .megamenu ul li .active,
  .navbar .megamenu ul li .active:hover {
    color: var(--color-white);
    background: var(--color-primary);
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    left: -100%;
    width: calc(100% - 70px);
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 30px 0;
    margin: 0;
    background: rgba(var(--color-secondary-rgb), 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--color-white-rgb), 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: var(--color-white);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid rgba(var(--color-secondary-light-rgb), 0.3);
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-toggle {
    display: block !important;
    color: var(--color-secondary);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    top: 50px;
    z-index: 9999;
    right: 20px;
  }

  .mobile-nav-toggle.bi-x {
    color: var(--color-white);
  }

  .mobile-nav-active {
    overflow: hidden;
    z-index: 9995;
    position: relative;
  }

  .mobile-nav-active .navbar {
    left: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(var(--color-secondary-rgb), 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
  # Index Page
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Animated veritas Section
  --------------------------------------------------------------*/
.veritas-animated {
  width: 100%;
  min-height: 50vh;
  // background: url("../img/veritas-bg.webp") center center;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}

.veritas-animated h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.veritas-animated h2 span {
  color: var(--color-primary);
}

.veritas-animated p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.veritas-animated .animated {
  margin-bottom: 60px;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 992px) {
  .veritas-animated .animated {
    max-width: 45%;
  }
}

@media (max-width: 991px) {
  .veritas-animated .animated {
    max-width: 60%;
  }
}

@media (max-width: 575px) {
  .veritas-animated .animated {
    max-width: 80%;
  }
}

.veritas-animated .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.veritas-animated .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.veritas-animated .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.veritas-animated .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.veritas-animated .btn-watch-video:hover {
  color: var(--color-primary);
}

.veritas-animated .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .veritas-animated h2 {
    font-size: 32px;
  }

  .veritas-animated p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .veritas-animated .btn-get-started,
  .veritas-animated .btn-watch-video {
    font-size: 14px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
  # Carousel veritas Section
  --------------------------------------------------------------*/
.veritas {
  width: 100%;
  min-height: 60vh;
  padding: 0;
  background: var(--color-black);
  // background: url("../img/veritas-bg.webp") center center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 140px 0 60px 0;
}

@media (max-width: 640px) {
  .veritas .container {
    padding: 0 60px;
  }
}

.veritas h2 {
  color: var(--color-secondary);
  margin-bottom: 25px;
  font-size: 48px;
  font-weight: 300;
  -webkit-animation: fadeInDown 1s both 0.2s;
  animation: fadeInDown 1s both 0.2s;
}

@media (max-width: 768px) {
  .veritas h2 {
    font-size: 30px;
  }
}

.veritas p {
  color: var(--color-secondary-light);
  -webkit-animation: fadeInDown 1s both 0.4s;
  animation: fadeInDown 1s both 0.4s;
  font-weight: 500;
  margin-bottom: 30px;
}

.veritas .img {
  margin-bottom: 40px;
  -webkit-animation: fadeInDownLite 1s both;
  animation: fadeInDownLite 1s both;
}

.veritas .btn-get-started {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 5px;
  transition: 0.5s;
  -webkit-animation: fadeInUp 1s both 0.6s;
  animation: fadeInUp 1s both 0.6s;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.veritas .btn-get-started:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.veritas .carousel-control-prev {
  justify-content: start;
}

@media (min-width: 640px) {
  .veritas .carousel-control-prev {
    padding-left: 15px;
  }
}

.veritas .carousel-control-next {
  justify-content: end;
}

@media (min-width: 640px) {
  .veritas .carousel-control-next {
    padding-right: 15px;
  }
}

.veritas .carousel-control-next-icon,
.veritas .carousel-control-prev-icon {
  background: none;
  font-size: 26px;
  line-height: 0;
  background: rgba(var(--color-secondary-rgb), 0.4);
  color: rgba(var(--color-white-rgb), 0.98);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.veritas .carousel-control-next-icon {
  padding-left: 3px;
}

.veritas .carousel-control-prev-icon {
  padding-right: 3px;
}

.veritas .carousel-control-prev,
.veritas .carousel-control-next {
  transition: 0.3s;
}

.veritas .carousel-control-prev:focus,
.veritas .carousel-control-next:focus {
  opacity: 0.5;
}

.veritas .carousel-control-prev:hover,
.veritas .carousel-control-next:hover {
  opacity: 0.9;
}

.veritas .carousel-indicators li {
  cursor: pointer;
  background: rgba(var(--color-secondary-rgb), 0.5);
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

.veritas .carousel-indicators li.active {
  opacity: 1;
  background: var(--color-primary);
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInDownLite {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownLite {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*--------------------------------------------------------------
  # Section
  --------------------------------------------------------------*/
.veritas-fullscreen {
  width: 100%;
  // background: url("~assets/utils/images/bg-banner.webp") center center;
  background-size: cover;
  position: relative;
  padding: 190px 0 50px;

}



@media (min-width: 1365px) {
  .veritas-fullscreen {
    background-attachment: fixed;
  }
}

.veritas-fullscreen h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 500;
  color: var(--color-white);
  font-family: var(--font-secondary);
}

.veritas-fullscreen h2 span {
  color: var(--color-primary);
}

.veritas-fullscreen p {
  color: rgba(var(--color-white-rgb), 0.6);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.veritas-fullscreen .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.veritas-fullscreen .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.veritas-fullscreen .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.veritas-fullscreen .btn-watch-video span {
  color: rgba(255, 255, 255, 1);
}

.veritas-fullscreen .btn-watch-video span:hover {
  color: rgba(255, 255, 255, 0.7);
}

.veritas-fullscreen .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.veritas-fullscreen .btn-watch-video:hover {
  color: var(--color-primary);
}

.veritas-fullscreen .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .veritas-fullscreen h2 {
    font-size: 32px;
  }

  .veritas-fullscreen p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .veritas-fullscreen .btn-get-started,
  .veritas-fullscreen .btn-watch-video {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
  # Static veritas Section
  --------------------------------------------------------------*/
.veritas-static {
  width: 100%;
  min-height: 50vh;
  // background: url("../img/veritas-bg.webp") center center;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}

.veritas-static h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.veritas-static h2 span {
  color: var(--color-primary);
}

.veritas-static p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.veritas-static .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.veritas-static .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.veritas-static .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.veritas-static .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.veritas-static .btn-watch-video:hover {
  color: var(--color-primary);
}

.veritas-static .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .veritas-static h2 {
    font-size: 32px;
  }

  .veritas-static p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .veritas-static .btn-get-started,
  .veritas-static .btn-watch-video {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
  # Featured Services Section
  --------------------------------------------------------------*/
.featured-services .service-item {
  padding: 30px;
  transition: all ease-in-out 0.4s;
  background: var(--color-white);
  height: 100%;
}

.featured-services .service-item .icon {
  margin-bottom: 10px;
}

.featured-services .service-item .icon i {
  color: var(--color-primary);
  font-size: 36px;
  transition: 0.3s;
}

.featured-services .service-item h4 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 24px;
}

.featured-services .service-item h4 a {
  color: var(--color-secondary);
  transition: ease-in-out 0.3s;
}

.featured-services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured-services .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0 60px 0 rgba(var(--color-secondary-rgb), 0.1);
}

.featured-services .service-item:hover h4 a {
  color: var(--color-primary);
}

/*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
.about .about-img {
  position: relative;
  margin: 60px 0 0 60px;
}




@media (max-width: 575px) {
  .about .about-img {
    margin: 30px 0 0 30px;
  }

  .about .about-img:before {
    inset: -30px 0 0 -30px;
  }
}

.about h3 {
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-weight: 300;
  font-size: 32px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .about h3 {
    font-size: 28px;
  }
}

.about .why-email p {
  background: var(--color-primary);
  color: var(--color-white);
  font-size: 36px;
  font-weight: 300;

}

.about .nav-pills {
  border-bottom: 1px solid rgba(var(--color-secondary-rgb), 0.2);
}

.about .nav-pills li+li {
  margin-left: 40px;
}

.about .nav-link {
  background: none;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-secondary);
  padding: 12px 0;
  margin-bottom: -2px;
  border-radius: 0;
  font-family: var(--font-secondary);
}

.about .nav-link.active {
  color: var(--color-primary);
  background: none;
  border-bottom: 3px solid var(--color-primary);
}

@media (max-width: 575px) {
  .about .nav-link {
    font-size: 16px;
  }

  .about .nav-pills li {
    margin-left: 0px;
    width: 30%;
    margin-left: 1.4%;
    margin-right: 1.4%;
  }

  .about .nav-pills li+li {
    margin-left: 0px;
  }

}

.about .tab-content h4 {
  font-size: 14px;
  margin: 0;
  font-weight: normal;
  color: var(--color-secondary);
}

.about .tab-content i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: var(--color-primary);
}


/*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
.cta {
  padding: 0;
  margin-bottom: 60px;
}

.cta .container {
  background: rgba(var(--color-secondary-rgb), 0.1);

}

@media (max-width: 992px) {
  .cta .container {
    padding: 60px;
  }
}

.cta .content h3,
#phishing h3 {
  color: var(--color-secondary);
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 40px;
}

.cta .content h3 em {
  font-style: normal;
  position: relative;
}

.cta .content h3 em:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  height: 10px;
  background: rgba(var(--color-primary-rgb), 0.5);
  z-index: -1;
}

.cta .content p.icon-title {
  text-align: center;
}

.cta .content p {
  color: var(--color-secondary);
  font-weight: normal;
  font-size: 16px;
  text-align: left;
}

.cta .content .cta-btn {
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  background: rgba(var(--color-primary-dark-rgb), 0.9);
}

.cta .content .cta-btn:hover {
  background: var(--color-primary);
}

.cta .img {
  position: relative;
}


.cta .img img {
  position: relative;
  z-index: 3;
}

@media (max-width: 500px) {
  .cta {
    margin-top: 0px !important;
  }
}

/*--------------------------------------------------------------
  # phishing section
  --------------------------------------------------------------*/

#phishing p {
  color: #485664;
  font-weight: 600;
  font-family: var(--font-secondary);
  margin-top: 22px;
}

#phishing img {
  width: 80px;
  height: auto;
}


/*--------------------------------------------------------------
  # banner midlle section
  --------------------------------------------------------------*/

.banner-checker {
  // background: url(../img/background.webp) no-repeat;
  padding: 33px 80px 33px;
  color: var(--color-white);
}

.banner-checker h4.title {
  font-size: 36px;
  width: 100%;
  font-weight: 600;
}

.banner-checker p {
  color: rgba(var(--color-white-rgb), 0.5);
  margin: 20px 0;
}

.banner-checker a.btn-getstarted {
  font-size: 16px;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 8px 50px;
  border-radius: 20px;
  transition: 0.3s;
  font-family: var(--font-secondary);
  font-weight: 600;
}

.banner-checker a.btn-getstarted:hover {
  background-color: #66b386;
}

@media(max-width: 500px) {
  .banner-checker {
    background: #4d6f5b !important;
    display: block !important;
    text-align: center;
    padding: 20px;
  }

  .icon-checker {
    margin-bottom: 20px;
  }
}


/*--------------------------------------------------------------
  # solution
  --------------------------------------------------------------*/

#solution h2 {
  color: #485664;
  font-size: 36px;
  font-weight: 300;
}


#solution p {
  color: #485664;
  font-size: 18px;
  font-weight: 500;
}


/*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 0;
  padding: 25px 20px;
  color: var(--color-secondary);
  box-shadow: 5px 5px 10px rgba(var(--color-secondary-rgb), 0.15);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0s;
  cursor: pointer;
  height: 100%;
}

.features .nav-link i {
  font-size: 32px;
  line-height: 0;
  color: var(--color-primary);

}

.features .nav-link h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 0 0;
  color: var(--color-secondary);
}

.features .nav-link:hover {
  color: var(--color-primary);
}

.features .nav-link.active {
  transition: 0.3s;
  background: #f1f1f1;
  border-color: #f1f1f1;
  ;
}

.features .nav-link.active h4 {
  color: var(--color-black);
}

.features .nav-link.active i {
  color: var(--color-primary) !important;
}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.features .tab-pane h3 {
  font-weight: 600;
  font-size: 36px;
  color: var(--color-secondary);
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-bottom: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  margin-right: 20px;
  color: var(--color-primary);
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

@media(max-width:500px) {
  .topic {
    margin-top: -15px !important;
  }
}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
.services h2 {
  font-size: 36px;
}

.services .img {
  border-radius: 8px;
  overflow: hidden;
}

.services .img img {
  transition: 0.6s;
}

.services .section-header p {
  color: var(--color-black);
  font-weight: 600;
  font-size: 18px;
}

.services .details {
  padding: 50px 30px;
  margin: -100px 30px 0 30px;
  transition: all ease-in-out 0.3s;
  background: var(--color-white);
  position: relative;
  // background: rgb(var(--color-white-rgb));
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0 25px rgba(var(--color-black-rgb), 0.1);
}

.services .details .icon {
  margin: 0;
  width: 72px;
  height: 72px;
  background: var(--color-white);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: var(--color-white);
  font-size: 28px;
  transition: ease-in-out 0.3s;
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  border: 6px solid var(--color-white);
}

.services .details h3 {
  color: var(--color-black);
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 16px;
  transition: ease-in-out 0.3s;
}

.services .details p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item:hover .details h3 {
  color: var(--color-black);
}

.services .service-item:hover .details .icon {
  background: var(--color-white);
  border: 2px solid var(--color-primary);
}

.services .service-item:hover .details .icon i {
  color: var(--color-primary);
}

.services .service-item:hover .img img {
  transform: scale(1.2);
}

/*--------------------------------------------------------------
  # news Section
  --------------------------------------------------------------*/
.testimonials {
  padding: 30px 0 80px;
  // background: url("../img/bg-award-last.webp");
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials h5 {
  font-size: 36px;
  font-weight: 300;
  color: var(--color-white);
  margin-bottom: 30px;
}


.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: var(--color-white);
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(var(--color-white-rgb), 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: var(--color-white);
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: rgba(var(--color-white-rgb), 0.6);
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: var(--color-yellow);
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(var(--color-white-rgb), 0.6);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(var(--color-white-rgb), 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-white);
  opacity: 1;
}

.x-img {
  height: 90px;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
.contact .map {
  margin-bottom: 40px;
}

.contact .map iframe {
  border: 0;
  width: 100%;
  height: 400px;
}

.contact .info {
  padding: 40px;
  box-shadow: 0px 2px 15px rgba(var(--color-black-rgb), 0.1);
  overflow: hidden;
}

.contact .info h3 {
  font-weight: 600;
  font-size: 24px;
}

.contact .info p {
  color: var(--color-secondary-light);
  margin-bottom: 30px;
  font-size: 15px;
}

.contact .info-item+.info-item {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(var(--color-secondary-rgb), 0.15);
}

.contact .info-item i {
  font-size: 24px;
  color: var(--color-primary);
  transition: all 0.3s ease-in-out;
  margin-right: 20px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--color-secondary);
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: var(--color-secondary-light);
}

.contact .php-email-form {
  width: 100%;
  background: var(--color-white);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: var(--color-white);
  background: var(--color-red);
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: var(--color-white);
  background: var(--color-green);
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: var(--color-white);
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--color-green);
  border-top-color: var(--color-white);
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  border-radius: 0px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-secondary-light);
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email] {
  height: 48px;
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
  height: 290px;
}

.contact .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 13px 50px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 0;
}

.contact .php-email-form button[type=submit]:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media(max-width:590px) {
  .tab-content iframe {
    width: 100%;
  }
}



/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.footer {
  color: var(--color-black);
  font-size: 14px;
}

.footer .footer-content img {
  width: 130px;
  height: auto;
  margin-bottom: 30px;
}

.footer .footer-content {
  background: var(--color-secondary);
  padding: 60px 0 30px 0;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

.footer .footer-content .footer-info h3 span {
  color: var(--color-primary);
}

.footer .footer-content .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: var(--font-primary);
  color: var(--color-white);
  font-weight: 300;
}

.footer .footer-content h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white);
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.footer .footer-content h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: var(--color-primary);
  bottom: 0;
  left: 0;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul i {
  padding-right: 2px;
  color: var(--color-white);
  font-size: 12px;
  line-height: 1;
}

.footer .footer-content .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: rgba(var(--color-white-rgb), 0.7);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
  color: var(--color-white);
}

.footer .footer-content .footer-newsletter form {
  margin-top: 30px;
  background: var(--color-white);
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

.footer .footer-content .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

.footer .footer-content .footer-newsletter form input[type=email]:focus-visible {
  outline: none;
}

.footer .footer-content .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--color-primary);
  color: var(--color-white);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-content .footer-newsletter form input[type=submit]:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
}

.footer .footer-legal {
  padding: 30px 0;

}

.footer .footer-legal .credits {
  padding-top: 4px;
  font-size: 13px;
  color: var(--color-black);
}

.footer .footer-legal .credits a {
  color: var(--color-black);
  font-weight: 600;
}

.footer .footer-legal .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #485664;
  color: var(--color-white);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .footer-legal .social-links a:hover {
  background: var(--color-primary);
  text-decoration: none;
}


/*phishing detector file */

#banner-default {
  // background: url(~assets/utils/images/bg-banner.webp) center center/cover;
  z-index: 1;
  padding: 0;
  margin-top: 120px;
  padding: 35px 0;
}

#banner-default .icon {
  width: 72px;
  height: 72px;
  border-radius: 50px;
  transition: .5s;
  background: #89b580;
  margin: auto;
}

#banner-default .icon-add {
  width: 72px;
  height: 72px;
  border-radius: 50px;
  transition: .5s;
  background: transparent;
  margin: auto;
}

#banner-default .icon img,
#banner-default .icon-add img {
  width: 50px;
  position: relative;
  top: 11px;
}


#banner-default h1 {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin: 0 0 10px;
  line-height: 56px;
  font-family: var(--font-secondary);
}

#banner-default h2 {
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  color: #c1c1c1;
  font-weight: normal;
}

/*--------------------------------------------------------------
  # questions
  --------------------------------------------------------------*/

.questions h3 {
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 20px;
}

.message-hacker-attack {
  background: var(--color-secondary);
  border-radius: 8px;
  padding: 20px;
}

.message-hacker-attack .percent {
  font-size: 55px;
  font-family: var(--font-secondary);
  color: var(--color-white);
  margin-bottom: 0;
  line-height: 1.2;
}

.message-hacker-attack .message {
  font-size: 20px;
  font-family: var(--font-secondary);
  color: var(--color-white);
  margin-bottom: 0;
}

.whats-email {
  background: #fafafa;
  padding: 0;
}

.whats-email h3,
.whats-email h4,
#benefits h3,
#benefits-seg h3,
#features h3,
#how-it-works h3 {
  color: var(--color-secondary);
  font-size: 36px;
  font-weight: normal;
  font-family: var(--font-secondary);
}

.whats-email p {
  line-height: 1.5;
  color: var(--color-secondary);
}

.whats-email h4 {
  font-size: 20px;
  color: var(--color-white);
  background: var(--color-secondary);
}

.whats-email .reason {
  text-align: center;
}

.whats-email .reason img {
  width: 72px;

}

.whats-email .reason p {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

.whats-email .img-reason img {
  position: absolute;
  bottom: 0;
}

@media(max-width:590px) {
  .whats-email .img-reason img {
    position: relative;
  }

  .whats-email .reason {
    text-align: center;
    width: 50% !important;
    float: left;
  }

  .reason-inline {
    display: inline !important;
  }
}

/*--------------------------------------------------------------
  # benefits
  --------------------------------------------------------------*/

#benefits h4,
#benefits-seg h4 {
  font-size: 16px;
  margin: 0;
  font-weight: normal;
  color: var(--color-secondary);
}

#benefits i,
#benefits-seg i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: var(--color-primary);
}

iframe {
  width: 100%;
  height: 400px;
}

.btn-dark {
  background-color: #333 !important;
}

.btn-dark:hover {
  background-color: #000 !important;
}

/* .box{
    display: flex;
    justify-content: center;
    align-items: center;
  } */

@media(max-width:500px) {
  iframe {
    height: auto;
  }
}

.p-hti p {
  margin: 10px 0 !important;
}

/*--------------------------------------------------------------
  # features
  --------------------------------------------------------------*/

#features h3 {
  background: #f1f1f1;
}

#features ul {
  list-style: none;
  padding: 0;
}

#features ul li {
  padding-bottom: 10px;
  text-align: left;
}

#features ul i {
  font-size: 20px;
  margin-right: 4px;
  color: var(--color-primary);
}

.icon-arrow {
  background: var(--color-primary);
  padding: 5px 0;
}

/*--------------------------------------------------------------
  # how it works
  --------------------------------------------------------------*/

#how-it-works .description-how-it-works {
  background: #f1f1f1;
  padding: 70px 30px;
}

#how-it-works .description-how-it-works h3 {
  margin-bottom: 100px;
}

#how-it-works .description-how-it-works p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
  # where work
  --------------------------------------------------------------*/
#where-work {
  padding-bottom: 0;
}

#where-work .service-item {
  background: #f8f7f7;
  border-radius: 0 0 10px 10px;
  border: 1px solid #485664;
}

#where-work .service-item .img {
  border-radius: 0;
}

#where-work i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: var(--color-primary);
}

#where-work h4 {
  font-size: 14px;
  margin: 0;
  font-weight: normal;
  color: var(--color-secondary);
  line-height: 1.5;
}

#where-work footer {
  background: var(--color-primary);
  margin-top: 20px;
  border-radius: 0 0 10px 10px;
  padding: 10px 0;
}

#where-work footer p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}

.list-works {
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media(max-width:590px) {
  .list-works {
    height: auto;
  }
}

/*--------------------------------------------------------------
  # ready
  --------------------------------------------------------------*/

.ready {
  background: #4d6f5b;
}

.ready .ready-text {
  font-family: var(--font-primary);
  color: var(--color-white);
  font-size: 48px;
  font-weight: bold;
}

.ready .ready-desc {
  font-family: var(--font-primary);
  color: var(--color-white);
  font-size: 24px;
}

.ready a.btn-getstarted {
  font-size: 16px;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 8px 50px;
  border-radius: 20px;
  transition: 0.3s;
  font-family: var(--font-secondary);
  font-weight: 600;
}

.ready a.btn-getstarted:hover {
  background: #66b386;
}

@media(max-width:590px) {
  .ready {
    padding-bottom: 50px !important;
  }
}

/*--------------------------------------------------------------
  # phishing simulator file
  --------------------------------------------------------------*/

/*--------------------------------------------------------------
  # problem
  --------------------------------------------------------------*/

.phishing-simulator .questions h3 {
  background: #f1f1f1;
  padding: 10px;
  color: var(--color-secondary);
  font-weight: 500;

}

.phishing-simulator .questions p {
  color: var(--color-secondary);

}

.phishing-simulator .questions h3.solution-title {
  background: var(--color-primary);
  padding: 10px;
  color: var(--color-white);
  font-weight: 500;
}

.phishing-simulator #features h3.how {
  background: none;
}

/*--------------------------------------------------------------
  # contact
  --------------------------------------------------------------*/

.contact-us .form-control {
  border-radius: 0;
  // margin: 20px 0;
}

.contact-us .form-control::placeholder {
  color: #AAA;
}

.contact-us textarea {
  resize: none;
  height: 250px;
}

.contact-us .text-right {
  text-align: right;
}

.contact-us button {

  font-size: 16px;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 8px 50px;
  border-radius: 20px;
  transition: 0.3s;
  font-family: var(--font-secondary);
  font-weight: 600;
  border: 1px solid var(--color-primary);
}

.contact-us .nav-link,
.contact-us .nav-link.active {
  border: 1px solid #cccccc;
  border-radius: 0;
  cursor: pointer;
  color: var(--color-black);
  text-align: center;
}

.contact-us .nav-link h4 {
  margin-bottom: 0;
}

.contact-us .nav-link.active {
  background: #f1f1f1;
}

.contact-us #features ul {
  border: none;
}

.contact-us #features .tab-content {
  text-align: left;
  margin-top: 25px;
}

/*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #473d3a;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #366f51;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #afa29e;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li+li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #656262;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #448a66;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #366f51;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #313030;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #473d3a;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #988782;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #554945;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #366f51;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #473d3a;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(71, 61, 58, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #a4a2a2;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #313030;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #366f51;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #473d3a;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #635551;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #366f51;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #366f51;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #473d3a;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #554945;
}

.blog .blog-pagination {
  color: #7f6d68;
  margin-bottom: 50px;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #473d3a;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #366f51;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #473d3a;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #448a66;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #366f51;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #473d3a;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #448a66;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #989595;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #473d3a;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #366f51;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #989595;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #8d7973;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #f4f2f2;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #366f51;
  background: #366f51;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #ddd7d6;
  font-size: 14px;
}

/*--------------------------------------------------------------
  # Free URL Checker
  --------------------------------------------------------------*/

.fuc input[type=submit] {
  width: 10%;
  padding: 8px 5px;
  background-color: #89b580;
  color: #fff;
  font-size: 20px;
  border: none;
  height: 50px;
  outline: none;
  transition: 0.3s;
}

.fuc input[type=text] {
  width: 90%;
  border: 1px solid #ddd;
  padding: 8px 5px;
  border-right: none;
  height: 50px;
  outline: none;
}

.fuc input[type=submit]:hover {
  background-color: #4d6f5b;
}

.social-media {
  width: 35px;
  margin-right: 10px;
}

.share {
  margin-top: 100px;
}

.fuc .nav-tabs {
  border: none;
}

.fuc .nav-tabs .nav-link {
  border: solid 1px #ccc;
  border-radius: 5px;
  cursor: pointer;
  color: #485664;
}


.fuc .nav-tabs .nav-link h4 {
  margin-top: .5rem;
  font-size: 16px;
}

.fuc .tab-content {
  padding: 30px 0;
}

.fuc .nav-link.active,
.fuc .nav-tabs .nav-link:hover {
  background: #89b580;
  color: #fff !important;
}

.fuc .tab-content h4 {
  margin: 20px 0 30px;
  font-weight: 600;
  font-size: 20px;
}

.fuc .tab-content h3 {
  font-size: 20px;
  margin: 30px 0;
}

.fuc .tab-content a {
  text-decoration: underline;
  color: #485664;
  font-weight: 500;
}

.fuc .tab-content a:hover {
  text-decoration: none;
  color: #89b580;
}

.fuc .result-box {
  background-color: #f1f1f1;
  padding: 20px;
}

.fuc .result-box h3 {
  margin: 0 0 30px;
}

.fuc .value-box {
  font-weight: 700;
}

.report-action {
  background-color: #4d6f5b;
  padding: 20px;
  color: #fff;
  margin: 40px 0;
}

.report-action h4 {
  font-weight: 600;
  margin: 0 0 20px !important;
}

.report-action .button-action {
  background-color: #89b580;
  color: #fff !important;
  font-weight: 600;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none !important;
  border-radius: 5px;
}

.report-action .button-action:hover {
  background-color: #99d48e;
}

.details {
  margin: 40px 0;
}

.details .row {
  margin: 5px 0;
}

.details-title {
  background-color: #f1f1f1;
  color: #485664;
  font-weight: 600;
  padding: 10px 0 10px 10px;
}

.label-details {
  font-weight: 600;
  width: 15%;
  text-align: right;
}

.value-details {
  width: 85%;
}

.details-subtitle {
  font-size: 18px;
  margin-bottom: 10px !important;
}

.details-subtitle::before {}

.details p {
  margin: 5px 0 5px 120px;
}

.details p.domain-main {
  font-weight: 600;
}

.dot-local {
  font-weight: 600;
  margin-left: 5px;
}

.link-search {
  color: #89b580 !important;
}

.call-to-action {
  margin: 30px 0;
  font-size: 18px;
  font-weight: 600;
}

.create-account {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
  background-color: #89b580;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none !important;
  margin-left: 10px;
}

.create-account:hover {
  background-color: #4d6f5b;
}

.symbol-result {
  width: 30px;
  margin-right: 10px;
  position: relative;
  top: -3px;
}

.new-verification {
  background-color: #313030;
  color: #fff !important;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none !important;
}

.new-verification:hover {
  background-color: #485664;
}

@media(max-width:590px) {
  .fuc input[type=submit] {
    width: 20%;
  }

  .fuc input[type=email] {
    width: 80%;
  }

  .social-media {
    margin-top: 10px;
  }

  .label-details {
    width: 50%;
    word-wrap: break-word;
  }

  .value-details {
    width: 50%;
    word-wrap: break-word;

  }

  .details p {
    margin: 5px 0 5px 20px;
  }


}

/*--------------------------------------------------------------
  # F.A.Q
  --------------------------------------------------------------*/
.section-bg {
  background-color: #f1f1f1;
}

.faq h2 {
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
}

.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  line-height: 24px;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #448a66;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #448a66;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}




/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.add-on p {
  text-align: left;
  margin: 30px 0;
}

@media(max-width:590px) {
  .add-on iframe {
    width: 100% !important;
    height: auto;
  }
}

/*--------------------------------------------------------------
  # Rating
  --------------------------------------------------------------*/

[data-star-rating] .gl-star-rating--stars>span {
  height: 50px !important;
  width: 50px !important;
}

/*--------------------------------------------------------------
  # Login
  --------------------------------------------------------------*/

#banner-default-login {
  width: 100%;
  height: 45vh;
  background: url("~assets/utils/images/bg-banner.jpg") center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 0;
  margin-bottom: -120px;
}

#banner-default-login:before {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.login {
  position: relative;
  z-index: 2;
}

.login .box-input {
  padding: 40px 30px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  min-height: auto;
  display: block;
  border-color: #4d6f5b;
}

.banner-login {
  margin-bottom: -280px !important;
}

.login fieldset {
  margin: 20px 0;
  text-align: left;
}

.login input {
  width: 100%;
  border: 1px solid #ddd;
  padding: 8px 5px;
  outline: none;
  border-radius: 5px;
}

.login ::placeholder {
  font-size: 16px;
  color: #ddd;
  font-family: "Open Sans", sans-serif;
}

.login label {
  font-weight: 600;
}

.login input[type=submit] {
  background-color: #89b580;
  color: #fff;
  font-size: 20px;
  outline: none;
  border-radius: 5px;
}

.login input[type=submit]:hover {
  background-color: #4d6f5b;
}

.login .link-forgot {
  text-decoration: underline;
}

.login .link-forgot:hover {
  text-decoration: none;
  color: #4d6f5b;
}

.login p {
  font-weight: 600;
  color: #999999;
}

.login button {
  border: 2px solid #89b580;
  color: #473d3a;
  background-color: transparent;
  padding: 3px 15px;
  margin-left: 10px;
}

.login button:hover {
  border: 2px solid #4d6f5b;
  color: #fff;
  background-color: #4d6f5b;

}

.login-microsoft,
.login-google {
  border: solid 2px #89b580;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-microsoft:hover,
.login-google:hover,
.login-microsoft:focus,
.login-google:focus {
  background-color: #f1f1f1;
  color: #485664;
}

.login-microsoft img {
  width: 30px;
}

@media(max-width:480px) {
  .banner-login {
    height: 45vh !important;
  }
}

/*--------------------------------------------------------------
  # Create an account
  --------------------------------------------------------------*/

.signup-side {
  height: 100vh;
}

.signup * {
  margin: 0;
  padding: 0;
}

.signup-why {
  padding: 50px;
}

.signup-why h4 {
  font-weight: 500;
}

.signup fieldset {
  margin: 20px 0;
  text-align: left;
}

.signup input {
  width: 100%;
  border: 1px solid #ddd;
  padding: 8px 5px;
  outline: none;
}

.signup ::placeholder {
  font-size: 16px;
  color: #999;
  font-family: "Open Sans", sans-serif;
}

.signup label {
  font-weight: 600;
}

.signup input[type=submit] {
  background-color: #89b580;
  color: #fff;
  font-size: 20px;
  outline: none;
}

.signup input[type=submit]:hover {
  background-color: #4d6f5b;
}

.signup .sigin {
  font-weight: 700;
}

.signup .sigin:hover {
  text-decoration: underline;
}

.signup input[type=checkbox] {
  width: auto;
  outline: none;
  cursor: pointer;  
}

.signup .check {
  padding-left: 20px;
}

.bg-default {
  background-color: #f1f1f1 !important;
}

.signup .icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #fff;
  border: 3px #89b580 solid;
}

.signup .icon img {
  width: 45px;
  position: relative;
}

.signup .icon-box {
  align-items: center;
  margin: 30px 0;
}

.bg-default p {
  display: none;
}

.signup-side .link-account {
  text-decoration: underline;
  color: #4d6f5b;
}

.signup-side .link-account:hover {
  text-decoration: none;
  color: #89b580;
}

@media(max-width:500px) {
  .signup-side {
    padding: 30px;
  }
}

.me-3 {
  margin-right: 1rem !important;
}
