.dropdown{
  z-index:1000;
  position:absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  width : 150px;
  left: 100px;
  padding: 0px;
  list-style-type: none;
  background-color: white;
}
.dropdownitem{
  padding-left: 5px;
  padding-top:5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.dropdown li:hover{
  background: #F8F8F8;
}
